.numbers table:last-child {
  margin-right: 0px;
}
.numbers th {
  width: 33.33%;
}

.numbers th span:first-child{
  position: relative;
  top:2px;
}

.numbers td {
  border-bottom: 1px dashed;
  border-right: 1px dashed;
  text-align: right;
  padding: 2px 10px 2px 0;
}

.numbers td:last-child {
  border-right: 0;
}

.numbers .up-down-arrow {
  float: right;
  margin-right:10px;
  cursor: pointer;
  position: relative;
  bottom: 1px;
  color: var(--pink);
}

.statistics-header {
  float:left;
  width:50%;
}

.draw-dates-total {
  float:right;
  width:50%;
  padding: 20px 0 10px 0;
  text-align: right;
  margin-top: 9px;
}

.table-1 {
  height:621.5px;
}

.table-2 {
  height:598.5px;
}

.numbers th:first-child,
.numbers td:first-child {
  width:20%;
}

.numbers th:last-child,
.numbers td:last-child {
  width:20%;
}

@media only screen and (max-width: 500px) {
  .numbers th:first-child,
  .numbers td:first-child {
    width:25%;
  }
  .numbers th:last-child,
  .numbers td:last-child {
    width:25%;
  }
}