.chart-balls-container {
  float:left;
  width:100%;
  padding-bottom: 12px;
}

.chart-balls-container > div{
  float:left;
  width:100%;

}

.chart-balls {
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 25px;
  height: 20px;
  float: left;
  margin: 0 8px 8px 0;
  padding-top: 5px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.chart-number-balls {
  background-color: yellow;
}

.chart-balls.active {
  background-color: var(--pink);
  color: #fff;
}