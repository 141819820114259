body {
  --pink: rgb(244 114 182 / 1);
  --white: rgb(229 231 235 / 1);
  --black: rgb(59 63 75 / 1);
  color : var(--black);
  margin: 0;
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  font-style: normal;
  background-color: lightblue;
}