.App {
  margin:20px;
}

a {
  color: inherit;
}

.dotted {
  border-bottom: 1px dotted var(--black);
}

.accent {
  color: var(--pink);
  filter: drop-shadow(2px 2px 0px rgba(157, 23, 77));
}

.header {
  margin:20px 0;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-size: xx-large;
}

.sub1 {
  padding:20px 0 10px 0;
  font-weight:700;
  text-transform: uppercase;
  font-size: x-large;
}

.selector a.mm {
  float:right;
}

.selector a.active {
  color: var(--pink);
  filter: drop-shadow(2px 2px 0px rgba(157, 23, 77));
}

.date-range {
  padding: 20px 0 10px 0;
}

.date-range input{
  padding:2px 4px;
}

.date-range .last-date{
  margin-left: 10px;
}

.date-range .last-date a{
  margin-left: 10px;
}

.date-range .last-date a.active{
  color: var(--pink);
}

.date-range .last-date .download {
  cursor: pointer;
  float: right;
  font-size:large;
}

table {
  border: 1px solid;
  width: 100%;
  border-collapse: collapse;
  float: left;
}

table th {
  background-color: var(--black);
  color: var(--white);
  text-transform: uppercase;
  font-weight: normal;
}

table th {
  border-right: 1px solid var(--white);
}

table th:last-child {
  border-right: none;
}


table tr:nth-child(even) {
  background-color: #fff;
}

table tr:nth-child(odd) {
  background-color: var(--white);
}

.table-container {
  overflow-y: auto;
  scrollbar-color: var(--pink)  var(--black) ;
  width: calc(50% - 10px);
  margin-right:20px;
  float:left;
}

.table-container:last-child {
  margin-right: 0px;
}

@media only screen and (max-width: 500px) {
  .table-container {
    width: 100%;
    margin-bottom: 10px;
  }

  table th {
    font-size: medium;
  }

  .date-range .last-date{
    margin-left: 0;
    display: block;
    margin-top: 5px;
  }
}
